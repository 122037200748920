// @vue/component
export default {
  model: {
    prop: 'value',
    event: 'update'
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    ariaInvalid: {
      type: [Boolean, String],
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    plaintext: {
      type: Boolean,
      default: false
    },
    autocomplete: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    },
    formatter: {
      type: Function,
      default: null
    },
    trim: {
      type: Boolean,
      default: false
    },
    number: {
      type: Boolean,
      default: false
    },
    lazyFormatter: {
      type: Boolean,
      value: false
    }
  },
  data() {
    return {
      localValue: this.stringifyValue(this.value)
    }
  },
  computed: {
    computedClass() {
      return [
        {
          // Range input needs class custom-range
          'custom-range': this.type === 'range',
          // plaintext not supported by type=range or type=color
          'form-control-plaintext':
            this.plaintext && this.type !== 'range' && this.type !== 'color',
          // form-control not used by type=range or plaintext. Always used by type=color
          'form-control': (!this.plaintext && this.type !== 'range') || this.type === 'color'
        },
        this.sizeFormClass,
        this.stateClass
      ]
    },
    computedAriaInvalid() {
      if (!this.ariaInvalid || this.ariaInvalid === 'false') {
        // this.ariaInvalid is null or false or 'false'
        return this.computedState === false ? 'true' : null
      }
      if (this.ariaInvalid === true) {
        // User wants explicit aria-invalid=true
        return 'true'
      }
      // Most likely a string value (which could be the string 'true')
      return this.ariaInvalid
    }
  },
  watch: {
    value(newVal, oldVal) {
      if (newVal !== oldVal && newVal !== this.localValue) {
        this.localValue = this.stringifyValue(newVal)
      }
    }
  },
  mounted() {
    const value = this.stringifyValue(this.value)
    if (value !== this.localValue) {
      this.localValue = value
    }
  },
  methods: {
    stringifyValue(value) {
      return value === null || typeof value === 'undefined' ? '' : String(value)
    },
    getFormatted(value, event, force = false) {
      value = this.stringifyValue(value)
      if ((!this.lazyFormatter || force) && typeof this.formatter === 'function') {
        value = this.formatter(value, event)
      }
      return value
    },
    updateValue(value) {
      value = this.stringifyValue(value)
      if (this.localValue !== value) {
        // keep the input set to the value before modifiers
        this.localValue = value
        if (this.number) {
          // Emulate .number modifier behaviour
          const num = parseFloat(value)
          value = isNaN(num) ? value : num
        } else if (this.trim) {
          // Emulate .trim modifier behaviour
          value = value.trim()
        }
        // Update the v-model
        this.$emit('update', value)
      }
    },
    onInput(evt) {
      // evt.target.composing is set by Vue
      // https://github.com/vuejs/vue/blob/dev/src/platforms/web/runtime/directives/model.js
      if (evt.target.composing) {
        return
      }
      const formatted = this.getFormatted(evt.target.value, evt)
      if (formatted === false || evt.defaultPrevented) {
        return
      }
      this.updateValue(formatted)
      this.$emit('input', formatted)
    },
    onChange(evt) {
      // evt.target.composing is set by Vue
      // https://github.com/vuejs/vue/blob/dev/src/platforms/web/runtime/directives/model.js
      if (evt.target.composing) {
        return
      }
      const formatted = this.getFormatted(evt.target.value, evt)
      if (formatted === false) {
        return
      }
      this.updateValue(formatted)
      this.$emit('change', formatted)
    },
    onBlur(evt) {
      // lazy formatter
      if (this.lazyFormatter) {
        const formatted = this.getFormatted(evt.target.value, evt, true)
        if (formatted === false) {
          return
        }
        this.updateValue(formatted)
      }
      // Emit native blur event
      this.$emit('blur', evt)
    },
    focus() {
      // For external handler that may want a focus method
      if (!this.disabled) {
        this.$el.focus()
      }
    },
    blur() {
      // For external handler that may want a blur method
      if (!this.disabled) {
        this.$el.blur()
      }
    }
  }
}
